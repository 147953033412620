import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [2,3,7,6,8];

export const dictionary = {
		"/(auth)": [9,[2]],
		"/(auth)/api/reviews": [26,[2]],
		"/login": [~27],
		"/logout": [28],
		"/(auth)/[orgId]/[[workspace]]/(share)/awards": [~21,[2,3,4],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(leads)/contactleads": [~18,[2,3,4],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(invites)/corrupted-invites": [~13,[2,3,4,7],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(insights)/invite-insights": [~11,[2,3,4,6],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(invites)/last-chance": [~14,[2,3,4,7],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(leads)/multileads": [~19,[2,3,4],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(share)/pdf": [~22,[2,3,4],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(insights)/review-insights": [~12,[2,3,4,6],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/reviews": [~24,[2,3,4,8],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/reviews/inbox": [~25,[2,3,4,8],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(invites)/scheduled-invites": [~15,[2,3,4,7],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(leads)/singleleads": [~20,[2,3,4],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(invites)/staged-invites": [~16,[2,3,4,7],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(invites)/suspended-invites": [~17,[2,3,4,7],[,,5]],
		"/(auth)/[orgId]/[[workspace]]/(share)/widgets": [~23,[2,3,4],[,,5]],
		"/(auth)/[orgId]/[[workspace]]": [~10,[2,3,4],[,,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';
import { dev } from '$app/environment'
import * as Sentry from '@sentry/sveltekit'

if (!dev) {
  Sentry.init({
    dsn: 'https://af39f9391f176273db3db485c3263329@o4506773784494080.ingest.sentry.io/4506773788295168',
    tracesSampleRate: 1.0,

    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0
  })
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry()
